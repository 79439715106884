<template>
  <div>
    <v-overlay v-model="localOpen" />
    <v-navigation-drawer
      v-model="localOpen"
      fixed
      app
      left
      :width="width ? width : 420"
      temporary
      class="drawer"
      stateless
      hide-overlay
    >
      <div
        id="drawer-content"
        class="pb-16"
        tabindex="1"
        ref="drawercontent"
      >
        <slot></slot>
      </div>

      <template slot="prepend">
        <v-list-item class="bg-sand">
          <v-list-item-content>
            <h3 class="drawer-title">{{ title }}</h3>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="close"
              color="black"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>

        <slot name="prepend" />
      </template>

      <template slot="append">
        <slot name="append" />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: {
    open: {
      default: false,
    },
    title: {
      default: '',
    },
    width: {
      default: null,
    },
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          this.focusContent()
        }, 500)
      }
    },
  },
  methods: {
    close() {
      this.localOpen = false
    },
    focusContent() {
      this.$refs.drawercontent.focus()
    },
  },
  computed: {
    localOpen: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('update:open', val)
      },
    },
  },
}
</script>

<style lang="scss">
.drawer-title {
  font-family: CooperBT;
}
#drawer-content:focus {
  outline: none;
}
</style>
